body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #324161;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td.numero,
td.ore{
    text-align: right;
}

td.ore .ora::after{
    content:"h"
}
td.ore .minuto::after{
    content:"m"
}
td.ore .secondo::after{
    content:"s"
}
td.ore .secondo::after,
td.ore .minuto::after,
td.ore .ora::after{
    font-size: 12px;
    padding-right: 2px;
}
td.timestamp{
    text-align:center;
}

table thead th {
    text-align: center;
}

.footer img{
    border-radius: 1000px;
    border: solid 3px white;
}
.footer{
    position: fixed;
    bottom: 20px;
    right: 20px;
    color:white;
    font-size: 20px;
    display: flex;
    align-items: end;
}

.tableContainer{
    background-color: white;
    max-height: 60vh;
    overflow: auto;
    border-radius: 5px;
}
.table{
    margin-bottom: 0;
}
table {
    cursor: default;
}
.refreshBtn{
    color:white;
    padding: 12px;
    cursor: pointer;
    font-size: 20px;
    transition: color 0.3s ease;
    background-color: transparent;
    border: unset !important;
    outline: unset !important;
}
.refreshBtn:hover{
    color: rgb(201, 201, 201);
}
.refreshBtn:active{
    color: rgb(128, 128, 128);
}
#root{
    height: 100vh;
    position: fixed;
    width: 100vw;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container{
    margin-top: -15vh;
}
.header{
    position: fixed;
    top: 10px;
    left: 10px;
    color: white;
    font-size: 28px;
}
input[type="date"]{
    border: unset;
    outline: unset;
    padding: 10px 10px;
}
.input-group > *,
.input-group{
    max-height: 40px;
}

.loginLabel{
    color: white;
    font-size: large;
}